import React, { Fragment, useState, useEffect } from "react";
import { Link } from "gatsby";

import { HASURA_DOCS_LINK } from "../../utils/constants";
// import { OfficeHourscard } from "./OfficeHoursCard";
import cloudSupport from "./images/cloud-support.svg";
import devSupport from "./images/dev-support.svg";
import docs from "./images/docs.svg";
import arrowBlue from "./images/arrow-blue.svg";
import discord from "./images/discord.svg";
import blog from "./images/blog.svg";
import communityCall from "./images/community-call.svg";
import pattern from "./images/pattern.png";
import "./topbanner.scss";

// function onSupportClick(e) {
//   e.preventDefault();
//   if (window.Intercom) {
//     const interCom = window.Intercom;
//     const showIntercom = () => {
//       return interCom("showNewMessage");
//     };
//     showIntercom();
//   }
// }
const supportState = [
  {
    imgSrc: cloudSupport,
    title: "Cloud Support",
    desc: "Sign in to your Hasura Cloud dashboard and create a ticket.",
    btnText: "Create a Ticket",
    btnClass: "hasura-light-blue-btn",
    linkShowCircle: true,
    linkContentUrl: "https://status.hasura.io/",
    circleDot: true,
    btnLink: "https://cloud.hasura.io/support?pg=help&plcmt=body&cta=create-a-ticket&tech=default",
  },
  {
    imgSrc: devSupport,
    title: "Dev Support",
    desc: "Track issues, raise a bug or start feature discussions on GitHub.",
    btnText: "Raise a Bug",
    btnClass: "hasura-green-btn",
    linkContent: "Start a GitHub Discussion",
    linkContentUrl: "https://github.com/hasura/graphql-engine/discussions",
    btnLink: "https://github.com/hasura/graphql-engine/issues",
  },
  {
    imgSrc: docs,
    title: "Hasura Docs",
    desc: "Head over to our Docs to see how Hasura can help your product.",
    btnText: "Go to Docs",
    btnClass: "hasura-blue-btn",
    btnLink: HASURA_DOCS_LINK,
  },
];

const conversationState = [
  {
    imgSrc: discord,
    title: "Discord",
    desc: "Stay connected with our 8000+ member strong community of Hasura users on Discord.",
    linkUrl: "https://discord.com/invite/hasura",
  },
  {
    imgSrc: blog,
    title: "Blog",
    desc: "Stay up-to-date on everything Hasura and GraphQL and super charge your app development.",
    linkUrl: "https://hasura.io/blog/",
  },
  {
    imgSrc: communityCall,
    title: "Community Call",
    desc: "This is our way of staying accountable to you. Tune in on the last Wednesday of every month.",
    linkUrl: "/community/community-call/",
    internalLink: true,
  },
];
const TopBanner = () => {
  const [isError, setIsError] = useState(false);
  const [isItems, setIsItems] = useState(null);
  useEffect(() => {
    // if (window.Intercom) {
    //   const interCom = window.Intercom;
    //   const showIntercom = () => {
    //     return interCom("show");
    //   };
    //   showIntercom();
    // }
    fetch("https://status.hasura.io/api/v2/status.json")
      .then(res => res.json())
      .then(
        result => {
          setIsItems(result);
          setIsError(false);
        },
        error => {
          setIsError(true);
        }
      );
  }, []);
  // <div className="chatList chatBorderRightBottom minWidthChat">
  //   {/*eslint-disable-next-line*/}
  //   <a onClick={onSupportClick} tabIndex="0" role="button" onKeyDown={onSupportClick}>
  //     <img src={websiteChat} alt="Website Chat" />
  //     Website Chat
  //   </a>
  // </div>
  const support = supportState.map((list, index) => {
    return (
      <div key={index} className="supportHelpList">
        <div className="alignStart">
          <div className="supportHelpImg">
            <img src={list.imgSrc} alt={list.title} />
          </div>
          <div className="hasura-ibm-plex-sub-title">{list.title}</div>
          <div className="hasura-ibm-plex-article-desc-Small">{list.desc}</div>
        </div>
        <div className="buttonWrapper">
          {isItems && list.linkShowCircle && !isError ? (
            <a href={list.linkContentUrl} target="_blank" rel="noopener noreferrer">
              <div className="hasura-ibm-plex-btn">
                <div className="greenCircle"></div>
                {isItems?.status.description}
                <div className="arrow">
                  <img src={arrowBlue} alt="arrow" />
                </div>
              </div>
            </a>
          ) : null}
          {list.linkContent ? (
            <a href={list.linkContentUrl} target="_blank" rel="noopener noreferrer">
              <div className="hasura-ibm-plex-btn">
                {list.linkContent}
                <div className="arrow">
                  <img src={arrowBlue} alt="arrow" />
                </div>
              </div>
            </a>
          ) : null}
          {list.internalLink ? (
            <Link to={list.btnLink}>
              <button className={"hasura-btn hasura-btn-md " + [list.btnClass]}>
                {list.btnText}
              </button>
            </Link>
          ) : (
            <a href={list.btnLink}>
              <button className={"hasura-btn hasura-btn-md " + [list.btnClass]}>
                {list.btnText}
              </button>
            </a>
          )}
        </div>
      </div>
    );
  });

  const conversation = conversationState.map((list, index) => {
    return (
      <Fragment key={index}>
        {list.internalLink ? (
          <Link to={list.linkUrl} className="conversationList">
            <div className="hasura-ibm-plex-article-desc">
              <img src={list.imgSrc} alt={list.title} />
              {list.title}
            </div>
            <div className="hasura-ibm-plex-article-desc-Small">{list.desc}</div>
          </Link>
        ) : (
          <a href={list.linkUrl} className="conversationList">
            <div className="hasura-ibm-plex-article-desc">
              <img src={list.imgSrc} alt={list.title} />
              {list.title}
            </div>
            <div className="hasura-ibm-plex-article-desc-Small">{list.desc}</div>
          </a>
        )}
      </Fragment>
    );
  });

  return (
    <section className="commonSectionWrapper patternWrapper removePaddBottom">
      <img className="helpPattern" src={pattern} alt="Pattern" />
      <div className="containerWrapper">
        <div className="topBannerWrapperHelp">
          <h1 className="title">Hasura Help Center</h1>
          <div className="hasura-ibm-plex-article-desc mb-48">
            Our engineers are here to assist you with all your queries
          </div>
          {/* <OfficeHourscard /> */}
          <div className="supportHelpWrapper">{support}</div>
          <div className="conversationWrappper">
            <div className="hasura-ibm-plex--article-sub-title">Join the conversation</div>
            <div className="hasura-ibm-plex-article-desc-Small">
              Join our community of experienced developers, from over 83 countries, who are actively
              contributing, collaborating and helping others{" "}
              <span className="hasura-font-600">build with Hasura</span>
            </div>
            <div className="conversationListWrapper">{conversation}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopBanner;
