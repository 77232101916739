import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import TopBanner from "../components/help/topbanner";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/help.png",
};

const canonicalUrl = "https://hasura.io/help/";

const Help = props => (
  <Layout location={props.location}>
    <Seo
      title="Get Help from the Hasura Team | Hasura GraphQL Engine"
      description="Let us know if you have any questions about getting started or working with Hasura and GraphQL."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <TopBanner />
  </Layout>
);

export default Help;
